//
// Bottom navbar for narrow devices
// --------------------------------------------------

.navbar-bottom {
  @include media-breakpoint-up(lg) {
    display: none;
  }
  display: flex;
  flex-wrap: wrap; // allow us to do the line break for collapsing content
  align-items: center;
  padding: 0;
  margin-top: $navbar-padding-y;

  &::after {
    content: '';
    width: 100%;
    height: 2rem;
    background: linear-gradient(0deg, rgba($white,1) 25%, rgba($white,0.7) 66%, rgba($white,0) 100%);
    position: absolute;
    bottom: 100%;
    left: 0;
  }

  &.gray {
    &::after {
      background: linear-gradient(0deg, rgba($light,1) 25%, rgba($light,0.7) 66%, rgba($light,0) 100%) !important;
    }    
  }

  $icon-size: 40px;

  .navbar-nav {
    align-items: flex-start;
    margin: 0 0 .5rem 0;
    justify-content: space-around;
    width: 100%;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);

    .nav-item { 
      margin: 0;
      padding: .5rem;            
      padding-top: 0;
      height: $icon-size;
      position: relative;

      &.active,
      &:hover {
        position: relative;
        &::before {
          content: '';
          background-color: $white;
          clip-path: url(#navBottomClip);
          position: absolute;
          width: $icon-size * 2.75;
          height: $icon-size;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }              

        .nav-link {
          .icon {
            background-color: $primary;
            box-shadow: $box-shadow;
            transform: translateY(-$icon-size / 5);    
          }
        }
      }
    }

    .nav-link {
      width: $icon-size;
      height: $icon-size;
      padding: 0;
      position: relative;

      .icon {
        position: absolute;
        z-index: 10;
        top: 0;
        border-radius: 50%;              
        width: 100%;
        height: 100%;
        transform: translateY(0);
        transition: {
          property: transform;
          duration: 0.3s;
          timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
        }
      }

      img {
        width: 100%;
        height: 100%;
        padding: .5rem;
        object-fit: contain;
      }
    }
  }

  &.gray {

    &::after {
      background: linear-gradient(0deg, rgba($light,1) 25%, rgba($light,0.7) 66%, rgba($light,0) 100%) !important;
    }    

    .nav-item {
      &::before {
          content: '';
          background-color: $light !important;
        }     
    }

  }

}