// theme colors
//
$darker-blue:   #08003D;
$dark-blue:     #1A0E68;
$blue:          #3387FF;
$red:           #E31D1A;
$light-blue:    #84809e;

// grays
//
$white:    #fff;
$gray-100: #F5F5F5;
$gray-200: #D8D8D8;
$gray-300: #CECECE;
$gray-400: #707070;
$gray-500: #58585A;
$black:    #000;

$primary:       $darker-blue;
$secondary:     $dark-blue;
$accent:        $red;
$light:         $gray-100;
$dark:          $gray-500;
$highlight:     $blue;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "accent":     $accent,
    "light":      $light,
    "dark":       $dark,
    "black":      $black,
    "white":      $white,
    "dark-blue":  $dark-blue,
    "highlight":  $blue,
    "light-blue": $light-blue
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  255;

$enable-rounded:            false;

// Spacing
$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 3),
    7: ($spacer * 4),
    8: ($spacer * 5),
    9: ($spacer * 6),
    10: ($spacer * 7)
);

// Object Fit
$object-fits: (
    "fill":         fill,
    "contain":      contain,
    "cover":        cover,
    "scale-down":   scale-down,
    "none":         none
);

// Body
//
// Settings for the `<body>` element.
$body-bg:                   $white;
$body-color:                $gray-500;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-size-base:    1rem;
$font-size-xxs:     $font-size-base * .625;
$font-size-xs:      $font-size-base * .75;
$font-size-sm:      $font-size-base * .875;
$font-size-md:      $font-size-base * 1.125;
$font-size-lg:      $font-size-base * 1.25;
$font-size-xl:      $font-size-base * 1.375;
$font-size-xxl:     $font-size-base * 1.5;

// Font sizes
$font-size: 1rem;
$font-sizes: (
    xxs:    $font-size-xxs,
    xs:     $font-size-xs,
    sm:     $font-size-sm,
    base:   $font-size-base,
    md:     $font-size-md,
    lg:     $font-size-lg,
    xl:     $font-size-xl,
    xxl:    $font-size-xxl
);

// Font weights
$font-weight-100:     100;
$font-weight-200:     200;
$font-weight-300:     300;
$font-weight-400:     400;
$font-weight-500:     500;
$font-weight-600:     600;
$font-weight-700:     700;
$font-weight-800:     800;
$font-weight-900:     900;
$font-weight-950:     950;
$font-weights: (
    100:    $font-weight-100,
    200:    $font-weight-200,
    300:    $font-weight-300,
    400:    $font-weight-400,
    500:    $font-weight-500,
    600:    $font-weight-600,
    700:    $font-weight-700,
    800:    $font-weight-800,
    900:    $font-weight-900,
    950:    $font-weight-950
);

$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            'Comfortaa', $font-family-sans-serif;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * .9375;

$headings-font-weight:        700;

$line-height-base:  1.5;
$line-height-lg:    1.5;
$line-height-sm:    1.5;

// Components
//
// Define common padding and border radius sizes and more.
$border-radius: 5px;
$box-shadow:    0px 3px 6px rgba($black, 0.42);

// Forms
$input-color:                           $primary;

$form-group-margin-bottom:              1.5rem;

// Buttons

$btn-padding-x:                     1.375rem !default;
$btn-padding-x-sm:                  1rem !default;
$btn-padding-x-lg:                  1.5rem !default;

$btn-dark-color:                    $white !default;
$btn-light-color:                   $gray-500 !default;
$btn-font-weight:                   normal !default;
$btn-box-shadow:                    none !default;

$btn-icon-size:                     ($font-size-base * .9375) !default;

$btn-transition:                    color .25s ease-in-out, background-color .25s ease-in-out, border-color .25s ease-in-out, box-shadow .2s ease-in-out !default;

$btn-focus-box-shadow:              none !default;
$btn-active-box-shadow:             none !default;

// Z-index master list
$zindex-btn-scroll-top:             1025 !default;
$zindex-toolbar:                    1026 !default;
$zindex-offcanvas:                  1035 !default;
$zindex-toast:                      1040 !default;

// Navbar
$navbar-padding-y:                    $spacer * .75 !default;

$navbar-nav-link-padding-y:           1rem !default;
$navbar-nav-link-padding-x:           1.125rem !default;

$navbar-dark-color:                   rgba($white, .65) !default;
$navbar-dark-hover-color:             $white !default;
$navbar-dark-active-color:            $white !default;
$navbar-dark-disabled-color:          rgba($white, .4) !default;
$navbar-dark-toggler-border-color:    transparent !default;

$navbar-dropdown-min-width:           12.5rem !default;
$navbar-mega-dropdown-column-width:   15rem !default;

$navbar-tool-transition:              color .25s ease-in-out !default;
$navbar-tool-icon-box-size:           18px;
$navbar-tool-icon-font-size:          1.25rem !default;
$navbar-tool-label-size:              1.25rem !default;
$navbar-tool-label-bg:                $primary !default;
$navbar-tool-label-color:             $white !default;
$navbar-tool-label-font-size:         $font-size-xs !default;
$navbar-tool-label-font-weight:       $font-weight-600;
$navbar-tool-text-font-size:          $font-size-sm !default;

$navbar-dark-tool-color:              $white !default;
$navbar-dark-tool-hover-color:        $white !default;
$navbar-dark-tool-text-small-color:   rgba($white, .5) !default;

$navbar-stuck-box-shadow:             $box-shadow !default;


// Bottom Navbar
$toolbar-bg:                          $white !default;
$toolbar-box-shadow:                  -1.25rem 0 2rem 0 rgba($gray-500, .15) !default;
$toolbar-transition:                  all .3s .35s ease-in-out;
$toolbar-icon-size:                   1.25rem !default;
$toolbar-label-font-size:             .6875rem !default;
$toolbar-icon-color:                  $gray-500 !default;
$toolbar-label-color:                 $gray-500 !default;

// SVG Icons
//

// Utility Icons
$close-icon:            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.999 20'%3E%3Cdefs/%3E%3Cpath fill='%2308003d' d='M11.844 10.004l7.774-7.773a1.306 1.306 0 000-1.845 1.3 1.3 0 00-1.845 0L10 8.16 2.227.386a1.3 1.3 0 00-1.845 0 1.306 1.306 0 000 1.845l7.774 7.773-7.774 7.773a1.306 1.306 0 000 1.845 1.3 1.3 0 00.922.382 1.3 1.3 0 00.923-.382L10 11.848l7.773 7.774a1.3 1.3 0 00.923.382 1.3 1.3 0 00.922-.382 1.306 1.306 0 000-1.845z'/%3E%3C/svg%3E");
$trash-icon:            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 20'%3E%3Cdefs/%3E%3Cdefs/%3E%3Cpath d='M15.907 2.412a.559.559 0 00-.359-.243 1.526 1.526 0 00-.314-.025h-4.036V1.51a1.2 1.2 0 000-.158A1.453 1.453 0 009.679.003H6.367a1.284 1.284 0 00-.185 0 1.443 1.443 0 00-1.368 1.51v.624H.613a.5.5 0 00-.094 0 .574.574 0 00-.513.627.58.58 0 00.611.517h.266v15.13a1.406 1.406 0 000 .257 1.465 1.465 0 001.446 1.327q.068 0 .135-.006h11.1a1.461 1.461 0 00.218 0 1.482 1.482 0 001.347-1.6v-15.1h.329a.568.568 0 00.452-.878zm-5.876-.972v.681H5.985v-.164c0-.2-.008-.388 0-.576a.259.259 0 01.286-.228c1.156-.008 2.309-.008 3.465 0h.042a.272.272 0 01.253.287zm3.928 1.972V18.37c0 .4-.064.465-.486.465H2.533c-.421 0-.485-.061-.485-.466V3.298h11.907c.003.037.004.076.004.113z' class='a'/%3E%3Cpath d='M7.908 16.982a.618.618 0 00.112.011.527.527 0 00.343-.122.69.69 0 00.225-.543V5.883a1.487 1.487 0 00-.009-.27.582.582 0 00-1.147.012 2 2 0 00-.007.275v10.496a.573.573 0 00.483.586zM10.073 16.57a.579.579 0 00.5.42.441.441 0 00.051 0 .581.581 0 00.575-.539v-5.373-5.16a2.327 2.327 0 00-.007-.275.537.537 0 00-.492-.508.546.546 0 00-.63.4 1.2 1.2 0 00-.035.34v10.363a1.181 1.181 0 00.038.332zM5.403 16.992a.594.594 0 00.571-.612V5.904a1.211 1.211 0 00-.047-.392.5.5 0 00-.6-.358.934.934 0 00-.445.309.439.439 0 00-.075.334V16.4a.587.587 0 00.596.592z' class='a'/%3E%3C/svg%3E");
$bookmark-icon:         url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.333 30'%3E%3Cdefs/%3E%3Cg fill='none'%3E%3Cpath d='M20 0H3.333A3.329 3.329 0 00.017 3.333L0 30l11.667-5 11.667 5V3.333A3.343 3.343 0 0020 0z'/%3E%3Cpath fill='%23e31d1a' d='M3.33333588 1C2.05591583 1 1.0166664 2.04673004 1.0166664 3.33395958l-.01572227 25.14908981 10.271801-4.4022007.3939209-.16881942.39392089.16881942 10.27274895 4.40260697V3.33333016C22.33333588 2.04673003 21.28660583 1 19.99999618 1H3.33333589m0-1h16.6666603c1.8333397 0 3.3333397 1.5 3.3333397 3.33333015V30l-11.66666985-5L-.0000038 30 .0166664 3.33333015C.01666641 1.5 1.4999962 0 3.33333588 0z'/%3E%3C/g%3E%3C/svg%3E");
$bookmark-solid-icon:   url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.333 30'%3E%3Cdefs/%3E%3Cg fill='%23e31d1a' %3E%3Cpath d='M20 0H3.333A3.329 3.329 0 00.017 3.333L0 30l11.667-5 11.667 5V3.333A3.343 3.343 0 0020 0z'/%3E%3Cpath fill='%23e31d1a' d='M3.33333588 1C2.05591583 1 1.0166664 2.04673004 1.0166664 3.33395958l-.01572227 25.14908981 10.271801-4.4022007.3939209-.16881942.39392089.16881942 10.27274895 4.40260697V3.33333016C22.33333588 2.04673003 21.28660583 1 19.99999618 1H3.33333589m0-1h16.6666603c1.8333397 0 3.3333397 1.5 3.3333397 3.33333015V30l-11.66666985-5L-.0000038 30 .0166664 3.33333015C.01666641 1.5 1.4999962 0 3.33333588 0z'/%3E%3C/g%3E%3C/svg%3E");

$info-icon: url("/assets/images/icon_moreInfo.svg");

$mannequin-icon-male:             url("/assets/images/westex_maleMannequin.png");
$mannequin-icon-male-selected:    url("/assets/images/mannequin_male_selected.png");
$mannequin-icon-female:           url("/assets/images/westex_femaleMannequin.png");
$mannequin-icon-female-selected:  url("/assets/images/mannequin_female_selected.png");

$spec-sheet-btn:             url("/assets/images/spec-sheet.png");
$spec-sheet-btn-active:      url("/assets/images/spec-sheet-active.png");

$bg-img-transition:         background-image .15s ease-in-out;

// Uniform builder
//

$m-img-path: "/assets/images/male";
$f-img-path: "/assets/images/female";

// Garment Types
// $name, $y, $x, $deg, $w, $filename
$male-garment-types: (
    "mask"      0       0       7deg    77px   url("#{$m-img-path}/mask_highlight.png"),
    "pant"      376px  -30px   -11deg   80px  url("#{$m-img-path}/pant_highlight.png"),
    "l-sleeve"  147px  -46px    47deg   67px   url("#{$m-img-path}/longSleeve_highlight.png"),
    "s-sleeve"  76px   -28px    54deg   68px   url("#{$m-img-path}/shortSleeve_highlight.png"),
    "cuff"      327px  -43px   -39deg   62px   url("#{$m-img-path}/cuff_highlight.png"),
    "jacket"    254px  -38px   -36deg   47px   url("#{$m-img-path}/jacket_highlight.png"),
    "coverall"  289px  -38px   -29deg   127px  url("#{$m-img-path}/coverall_highlight.png")
);

$female-garment-types: (
    "mask"      0      -19px    7deg    77px   url("#{$f-img-path}/mask_highlight.png"),
    "pant"      376px  -30px   -11deg   67px   url("#{$f-img-path}/pant_highlight.png"),
    "l-sleeve"  147px  -46px    47deg   61px   url("#{$f-img-path}/longSleeve_highlight.png"),
    "s-sleeve"  76px   -28px    54deg   56px   url("#{$f-img-path}/shortSleeve_highlight.png"),
    "cuff"      327px  -43px   -49deg   58px   url("#{$f-img-path}/cuff_highlight.png"),
    "jacket"    254px  -43px   -36deg   47px   url("#{$f-img-path}/jacket_highlight.png"),
    "coverall"  289px  -38px   -29deg   127px  url("#{$f-img-path}/coverall_highlight.png")
);

// Garment Thumbnails
$thumb-male-garment-types: (
    "mask":         url("#{$m-img-path}/thumb_mask_highlight.png"),
    "pant":         url("#{$m-img-path}/thumb_pant_highlight.png"),
    "l-sleeve":     url("#{$m-img-path}/thumb_longSleeve_highlight.png"),
    "s-sleeve":     url("#{$m-img-path}/thumb_shortSleeve_highlight.png"),
    "cuff":         url("#{$m-img-path}/thumb_cuff_highlight.png"),
    "jacket":       url("#{$m-img-path}/thumb_jacket_highlight.png"),
    "coverall":     url("#{$m-img-path}/thumb_coverall_highlight.png")
);

$thumb-female-garment-types: (
    "mask":         url("#{$f-img-path}/thumb_mask_highlight.png"),
    "pant":         url("#{$f-img-path}/thumb_pant_highlight.png"),
    "l-sleeve":     url("#{$f-img-path}/thumb_longSleeve_highlight.png"),
    "s-sleeve":     url("#{$f-img-path}/thumb_shortSleeve_highlight.png"),
    "cuff":         url("#{$f-img-path}/thumb_cuff_highlight.png"),
    "jacket":       url("#{$f-img-path}/thumb_jacket_highlight.png"),
    "coverall":     url("#{$f-img-path}/thumb_coverall_highlight.png")
);
