//
// Westex cards styles
//

.wx-card {
  @extend .col-xl-2;
  @extend .col-md-3;
  @extend .col-sm-4;
  position: relative;
  word-wrap: break-word;
  background-clip: border-box;
  border: none;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: solid 1px $border-color;

  @include media-breakpoint-up(sm) {
    min-width: 170px;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &-img {
    width: 80px;
    height: 80px;
    object-fit: contain;

    @include media-breakpoint-up(sm) {
      flex-shrink: 0;
      width: 100%;
      height: unset;
    }

    &-tour {
      max-height: 10vh;
    }
  }

  &-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 0 0 1.25rem;
    font-size: $font-size-sm;

    @include media-breakpoint-up(sm) {
      padding: .75rem 0;
    }

    .card-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &-title {
    margin-bottom: .25rem;
    color: $black;

    &-resource{
      color: $black;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &-fluid {
      @extend .col;
      position: relative;
      word-wrap: break-word;
      background-clip: border-box;
      border: none;
      flex-direction: column;
      justify-content: space-between;

      .wx-card-inner {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      margin-left: .5rem;
      margin-right: .5rem;
    }

    .wx-card-img {
      flex-shrink: 0;
      width: 100%;
      height: unset;
    }

    .wx-card-body {
      padding: .75rem 0;
      flex: 1 1 auto;
      min-height: 1px;
      font-size: $font-size-sm;
    }
  }

}

.p-carousel-item {

    .wx-card-inner {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      margin-left: .5rem;
      margin-right: .5rem;
    }

    .wx-card-img {
      flex-shrink: 0;
      width: 100%;
      height: unset;

      @include media-breakpoint-down(sm) {
        width: 35vh;
        max-width: 100%;
      }

      &-lines {
        height: 250px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @include media-breakpoint-down(sm) {
          max-width: 100%;
          height: 35vh;
        }

        &-tour {
          max-width: 10vh;
          max-height: 10vh;
        }
      }
    }

    .wx-card-body {
      padding: .75rem 0;
      flex: 1 1 auto;
      min-height: 1px;
      font-size: $font-size-sm;
    }

    .btn {
      margin-left: .5rem;
      margin-right: .5rem;
    }

  }
