// Project utilities
//

// Text
//
.font-size {
    @each $size, $value in $font-sizes {
            &-#{$size} {
                  font-size: #{$value};
            }      
      }
}

.font-weight {
    @each $name, $value in $font-weights {
            &-#{$name} {
                  font-weight: #{$value} !important;
            }      
      }
}

.text-border {
    @each $name, $value in $theme-colors {
            &-#{$name} {
                  color: #{$value};
                  border-bottom: 1px solid #{$value};
            }      
      }
}

@each $key, $val in $object-fits {
    .object-fit-#{$key} {
        object-fit: $val;
    }
}

.wx-close {
      background: transparent;
      margin-left: auto;
      border: 0;

    &:focus,
    &.focus {
      box-shadow: none;
      outline: none;
    }

    &-icon {
      position: relative;
      width: 20px;
      height: 20px;
      display: block;
      outline: none;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: escape-svg($close-icon);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
}

.cursor-pointer {
      cursor: pointer;
}

.cursor-initial {
      cursor: initial;
}