//
// Main content area
//

* {
  -webkit-overflow-scrolling: auto;
}

body {
  overflow-y: auto;
}

.wx-content {
  order: 1;

  &.wx-gray {
    background-color: $light;
    min-height: subtract(100vh, 101px);
  }
}

body {
  @include media-breakpoint-down(sm) {
    background-color: $primary;
  }
}

#containerBg {
  background-color: white;
  min-height: subtract(100vh, 101px);
  margin-top: add(100px, constant(safe-area-inset-top));
  margin-top: add(100px, env(safe-area-inset-top));

  @include media-breakpoint-down(md) {
    margin-top: add(80px, constant(safe-area-inset-top));
    margin-top: add(80px, env(safe-area-inset-top));
  }

  @include media-breakpoint-down(sm) {
    margin-top: add(69px, constant(safe-area-inset-top));
    margin-top: add(69px, env(safe-area-inset-top));
  }
}

.mb-tour {
  margin-bottom: 6rem;
}

.scroll-snap {
  &-container {
    overflow-y: scroll;

    position: absolute;
    left: 15px;
    right: 0;
    bottom: 0;
    top: 0;

    min-height: subtract(100vh, 101px);

    scroll-behavior: smooth;

    @include media-breakpoint-down(md) {
      padding-left: 15px;
      padding-right: 15px;
      position: initial;
      left: initial;
      right: initial;
      bottom: initial;
      top: initial;
      max-height: subtract(100vh, 101px);
      min-height: subtract(98vh, 101px);
      padding-bottom: 4rem;

      &:hover {
        &::-webkit-scrollbar {
          width: 5px;
        }
      }

      &::-webkit-scrollbar {
        width: 2px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $light;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba($gray-500, .5);
        border-radius: 10px;
      }
    }

    @include media-breakpoint-down(sm) {
      // if we add snap scrolling back
      //scroll-snap-type: y mandatory;
    }
  }

  &-child {
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
  }
}

.dialog-modal {
  width: 55vw;
  max-height: 70vh !important;
}

.catalog-carousel {
  padding-top: 10px;
}

@include media-breakpoint-down(lg) {
  .dialog-modal {
    width: 75vw;
  }
}

@include media-breakpoint-down(md) {
  .catalog-title {
    margin-top: 1.5rem !important;
  }

  .dialog-modal {
    width: 95vw;
  }
}

.dashboard {
  &-svg {
    min-height: 62px;
  }

  &-full {
    min-height: subtract(100vh, 100px);
  }

  &-title {
    margin-bottom: 3rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }

  &-buttons {
    max-height: 365px;
    height: subtract(100vh, 260px);

    flex: 0 1 350px;
    .dashboard-btn {
      @extend .btn;
      @extend .btn-white;
      @extend .btn-round;
      @extend .d-flex;
      @extend .mb-4;
      max-height: 365px;
      height: subtract(100vh, 260px);
      overflow-y: auto;
    }

    &-container {
      .p-carousel {
        .p-carousel-content {
          .p-carousel-container {
            .p-carousel-items-container {
              max-height: 365px;
              height: subtract(100vh, 260px);
            }
          }
        }
      }
    }
  }
}

.ssd {
  &-list {
    &-item {
      border-bottom: 1px solid #D8D8D8;
      height: initial !important;
    }
  }

  &-swatch-img {
    width: 80px !important;
  }

  &-text-area {
    margin-left: 10px;
    width: subtract(100%, 110px);

    @include media-breakpoint-up(md) {
      margin-left: 20px;
      width: subtract(100%, 120px);
    }
  }

  &-input {
    border: 1px solid #707070;
    border-radius: 16pt;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2pt rgba(112, 112, 112, 0.5);
    }
  }

  &-download {
    border-radius: 16pt;
    background-color: #3387FF;
    color: white;
    border: none;
    padding: 3px 15px;
    outline: none !important;

    &:focus {
      box-shadow: 0 0 0 2pt rgba(51, 135, 255, 0.5);
    }

    &:disabled {
      background-color: #9FC1FD;
    }
  }

  &-my-sheets {
    border-radius: 16pt;
    background-color: white;
    color: #3387FF;
    border: 1px solid #3387FF;
    padding: 3px 15px;
    outline: none !important;

    &:focus {
      box-shadow: 0 0 0 2pt rgba(51, 135, 255, 0.5);
    }
  }
}

.standard-btn {
  &.btn-primary:disabled {
    background-color: #7C7C7C;
    border: 1px solid #58585A;
  }

  &.btn-accent:disabled {
    background-color: #7C7C7C;
    border: 1px solid #58585A;
  }

  &.btn:disabled {
    opacity: 1;
  }
}

.section-title {
  text-align: center;
  color: $primary;
  font-size: 1.5rem;
  &::after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 40px;
    height: 3px;
    border-radius: 3px;
    margin-top: .5rem;
    background-color: $accent;
  }
}

.p-button {
  background: #E31D1A !important;
  border: 1px solid #E31D1A !important;
  border-radius: 2px !important;
  padding: 0.5rem 3rem !important;
  font-family: $font-family-base !important;
}

.p-button-secondary {
  color: #E31D1A !important;
  background: none !important;
  border: none !important;
  padding: 0.5rem 1rem !important;
  font-family: $font-family-base !important;
}

.modal-inner-header {
  font-weight: 600;
  font-size: 1.15rem;
  font-family: $font-family-base !important;
}

.p-dialog-header {
  border-bottom: none !important;
  font-family: $font-family-base !important;
}

.p-dialog-footer {
  border-top: none !important;
}

.p-carousel-next.p-disabled,
.p-carousel-prev.p-disabled {
  display: none !important;
}

.mannequin-section .p-carousel .p-carousel-content .p-carousel-container .p-carousel-prev,
.mannequin-section .p-carousel .p-carousel-content .p-carousel-container .p-carousel-next {
  display: none !important;
}

.mannequin-section .p-carousel-next.p-disabled,
.mannequin-section .p-carousel-prev.p-disabled {
  display: initial !important;
}

.mannequin-section .p-carousel-next,
.mannequin-section .p-carousel-prev {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}

.mannequin-section .p-carousel-next {
  @include media-breakpoint-down(md) {
    margin-right: 65px !important;
  }
}

@include media-breakpoint-down(sm) {
  .p-carousel-container .p-carousel-next,
  .p-carousel-container .p-carousel-prev {
    display: none !important;
  }
}

.p-carousel {
  font-family: $font-family-base !important;
}

.p-carousel-item {
  flex-grow: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  word-wrap: break-word;
  background-clip: border-box;
}

.bg-img-dashboard{
  background: center;
  background-size: cover;
  background-image: url('/assets/images/dashboard.jpg');
}

.grayout {
  opacity: 0.5;
  filter: alpha(opacity = 50);
}

.map-container{
  position: relative;
}
.overlay {
  position: absolute;
  height:500px;
  width:1100px;
}

.sales-contact{
  padding: 16px 16px 8px 16px;

  &-container{
    background-color: white;
    border-radius: 5%;
    font-size: small;
    padding-left: 1rem;
  }

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    background-color: #08003D;
    color:white;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.sales-contact p{
    margin-bottom: 0.25rem !important;
    color: #08003D;
}
