//
// Rounded buttons
//

.btn-round {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}

.btn-circle {
    border-radius: 50%;
    padding: 0;
    font-size: .5rem;
    height: 40px;
    width: 40px;
    border:none;
    background-image: $spec-sheet-btn;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @include hover-focus {
      background-image: $spec-sheet-btn-active;
    }
  }

.wx-btn-group-toggle {
    display: inline-block;
    margin: .5rem;

    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }

    input[type=checkbox]:checked + .btn, input[type=radio]:checked + .btn {
        color: $accent;
        border: solid 1px $accent;
    }

    .btn {
        width: 100%;
        height: 100%;
        background-color: $white;
        color: $dark-blue;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include hover-focus {
            color: $accent;
            border: solid 1px $accent;
            background-color: $white;
        }
    }

}
