//
// Main content area
//

.wx-form {

    &.narrow {
        width: 100%;
        max-width: 500px;
    }

    .label {
        font-weight: 700;
    }

    &-control:not(textarea) {
        @extend .form-control;
        background-color: transparent;
        border: none;
        border-bottom: solid 1px transparent;

        &:focus {
            background-color: transparent;
            box-shadow: none;
        }      
    }

    &-search {
        &-bar {
            border-radius: 20px;
            position: relative;
            z-index: 100;
        }

        &-btn {
            width: 45px;
            border-radius: 0px 20px 20px 0px;
            &:focus {
                box-shadow: none !important;
            }
        }

        &-icon {
            position: relative;
            left: 5px;
        }

        &-group {
            background-color: #08003D;
            border-radius: 20px 20px 20px 20px;
        }
    }

    textarea.wx-form-control {
        @extend .form-control;
        border: none;
        border-radius: $border-radius;
        box-shadow: $box-shadow;

        &:focus {
            box-shadow: $box-shadow;
        }
        }

    &.primary {
        .label {
            color: $primary;
        }

        .wx-form-control {
            border-color: $primary;
        }
    }

    &.white {
        .label {
            color: $white;
        }

        .wx-form-control {
            border-color: $white;
        }
    }
}