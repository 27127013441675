  //
  // left and right side menus
  //

  .wx-sidebar {
    &-small {
      width: 70px;
      background-color: transparent;
    }

    &-hor {
      background-color: transparent;
    }

    &-container {
      position: fixed;
      z-index: 1000;
      height: calc(100vh - (100px + 1.25rem));
      padding-left: 0;
      padding-right: 0;
      flex: 0 1 390px;

      @include media-breakpoint-down(md) {
        width: calc(98% - 45px);
        max-width: 390px;
      }

      @include media-breakpoint-up(lg) {
        display: block;
        margin-top: 1.25rem;
        @supports (position: sticky) {
          top: calc(100px + 1.25rem);
          position: sticky;
        }
      }

      transition: {
        timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        duration: .75s;
        property: transform visibility;
      }

      &.standards {
        order: 0;

        &.open {
          transform: translateX(100%);
        }

        @include media-breakpoint-down(md) {
          right: 100%;

          .wx-sidebar-heading .icon {
            right: auto;
            top: -1rem;
            left: 100%;
            background-color: $light;
            padding: .5rem;
            display: block;
            width: 45px;
            border-bottom-right-radius: $border-radius;
            display: none;
          }
        }
      }

      &.uniform {
        order: 2;
        @include media-breakpoint-down(md) {
          right: -100%;
        }
      }

      &.resources {
        order: 2;
        @include media-breakpoint-down(md) {
          right: -100%;
        }
      }

    }

    background-color: $light;
    padding: 1rem 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

  &-heading {
    padding: 1rem .5rem;
    position: relative;

    .icon {
      width: 1.5rem;
      fill: $primary;
      position: absolute;
      right: .5rem;
      top: 0;
    }

    .title {
      color: $accent;
      font-weight: 700;
      border-bottom: 1px solid $accent;
    }
  }

  &-list {
    &-hor {
      flex-wrap: wrap;
      flex-direction: row;
    }

    &-container {
      display: block;
      max-height: 100%;
      overflow-y: auto;

      &:hover {
      &::-webkit-scrollbar {
        width: 5px;
      }
      }
      &::-webkit-scrollbar {
        width: 2px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $light;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba($gray-500, .5);
        border-radius: 10px;
      }

      @include media-breakpoint-down(md) {
        max-height: subtract(100vh, 230px);
      }
    }

    @extend .list-group;
    @extend .list-group-flush;

    &-item {
      @extend .list-group-item;
      @extend .custom-control;
      @extend .custom-checkbox;
      background-color: transparent;
      padding: 0 2rem 0 0;
      border-left: 0;
      border-right: 0;
    }

    &-label {
      @extend .custom-control-label;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 0.5rem;
        width: 80px;
        height: 80px;
      }
      .title {
        color: $black;
        font-weight: 700;
        font-size: $font-size-sm;

        @include media-breakpoint-up(xl) {
          font-size: $font-size-base * .9375;
        }
      }

      // Background-color and (when enabled) gradient
      &::before {
        top: auto;
        right: -1.25rem;
        left: auto;
      }

      // Foreground (icon)
      &::after {
        top: auto;
        right: -1.25rem;
        left: auto;
      }

    }

    .garment {
      position: relative;
      background-color: transparent;
      padding: 0;
      display: flex;
      height: 80px;
      margin: 0 0 .5rem .5rem;
      overflow: hidden;

      &-small {
        height: 64px;
        margin-bottom: 0.25rem;

        &-hor {
          height: 100px;
          margin-left: 0;
          margin-bottom: 1.5rem;
        }
      }

      &.active {
        .garment-img {
          border-color: $accent;
        }
      }

      &.on {

        .garment-swatch,
        .garment-body {
          visibility: visible;
        }

        .garment-img {
          img {
            opacity: 0;
          }

          @each $name, $filename in $thumb-male-garment-types {
            &.thumb-m-#{$name} {
              background-image: $filename;
              display: block;
              height: 100%;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
            &.thumb-m-#{$name} {
              background-image: $filename;
              display: block;
              height: 100%;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

          @each $name, $filename in $thumb-female-garment-types {
            &.thumb-f-#{$name} {
              background-image: $filename;
              display: block;
              height: 100%;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
      }

      &.disabled {
        .garment-img {
          border: solid 2px rgba($primary, .15);
          cursor: not-allowed;
          img {
            opacity: .5;
          }
        }
      }

      &-img {
        width: 80px;
        height: 100%;
        border: solid 2px $primary;
        flex-shrink: 0;

        &-small {
          background-color: white;
          width: 52px;
          margin-right: 5px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          padding: .25rem;
        }
      }

      &-swatch {
        width: 40px;
        height: 80px;
        flex-shrink: 0;
        visibility: hidden;
      }

      &-spec {
        width: 95px;
        height: 80px;

        &-full {
          width: 100px;
          height: 100px;
        }
      }

      &-body {
        margin-left: .5rem;
        visibility: hidden;
      }

      &-title {
        color: $black;
        font-size: .875rem;
        font-weight: 700;
        margin-bottom: .25rem;
        display: flex;
        align-items: center;
      }

      .trash {
        background: transparent;
        margin-left: auto;
        border: 0;

        &:focus,
        &.focus {
          box-shadow: none;
          outline: none;
        }

        &-icon {
          position: relative;
          width: 20px;
          height: 20px;
          display: block;
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: escape-svg($trash-icon);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }

      &-text {
        font-size: .75rem;
        margin-right: .25rem;
      }
    }
  }

}
