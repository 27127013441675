//
// Navbar
// --------------------------------------------------

.wx-navbar {
  position: fixed;
  top: 0;
  z-index: 1071;
  width: 100vw;
  margin-top: constant(safe-area-inset-top); /* iOS 11.0 */
  margin-top: env(safe-area-inset-top); /* iOS 11.2 */
}

.navbar-brand {
    max-width: 25%;
    min-width: 125px;

    img {
      width: 100%;

      @include media-breakpoint-down(md) {
        width: 200px;
      }
    
      @include media-breakpoint-down(sm) {
        width: 150px;
      }
    }

  }

// Styles for collapsed navbar (mobile view)

// Navbar nav
.navbar-nav {
  .nav-item {
    margin-bottom: $navbar-nav-link-padding-y / 4;
  }
  .nav-link {
    padding: {
      top: $navbar-nav-link-padding-y / 2;
      bottom: $navbar-nav-link-padding-y / 2;
    }
  }
}

// Navbar tools

.navbar-tool {
  position: relative;
  display: flex;
  margin: 0 .5rem;
  align-items: center;
  &.dropdown::before {
    position: absolute;
    bottom: -$spacer;
    left: -10%;
    width: 120%;
    height: $spacer;
    content: '';
  }
  .dropdown-menu {
    margin-top: $spacer / 2 !important;
  }
  .navbar-tool-label {
    position: absolute;
    top: -($navbar-tool-label-size / 4);
    right: -($navbar-tool-label-size / 4);
    width: $navbar-tool-label-size;
    height: $navbar-tool-label-size;
    border-radius: 50%;
    background-color: $navbar-tool-label-bg;
    color: $navbar-tool-label-color;
    font: {
      size: $navbar-tool-label-font-size;
      weight: $navbar-tool-label-font-weight;
    }
    text-align: center;
    line-height: $navbar-tool-label-size;
  }
  .navbar-tool-tooltip { display: none; }
  @include media-breakpoint-up(lg) {
    .navbar-tool-tooltip {
      display: block;
      position: absolute;
      top: -.5rem;
      left: 50%;
      transform: translateX(-50%);
      transition: opacity .2s ease-in-out, top .2s ease-in-out;
      padding: .0625rem .375rem;
      border-radius: $border-radius-sm;
      background-color: $tooltip-bg;
      color: $tooltip-color;
      font-size: .6875rem;
      white-space: nowrap;
      opacity: 0;
    }
    &:hover .navbar-tool-tooltip {
      top: -.75rem;
      opacity: .9;
    }
  }
}
.navbar-tool-icon-box {
  position: relative;
  width: $navbar-tool-icon-box-size;
  height: $navbar-tool-icon-box-size;
  transition: $navbar-tool-transition;
  border-radius: 50%;
  text-align: center;
  
  &.dropdown-toggle::after { display: none; }
}
.navbar-tool-icon {
  font-size: $navbar-tool-icon-font-size;
  line-height: $navbar-tool-icon-box-size;
}
.navbar-tool-text {
  display: none;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: .875rem;
  transition: $navbar-tool-transition;
  font-size: $navbar-tool-text-font-size;
  text-decoration: none !important;
  white-space: nowrap;
  > small {
    display: block;
    margin-bottom: -.125rem;
  }
}
.dropdown .navbar-tool-text {
  @include caret;
}

@include media-breakpoint-down(md) {
  .search-box { display: none; }
}


// Styles for expanded navbar

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($next) {
        &.navbar {
          padding-bottom: 0; 
        }
        &.navbar-floating {
          .navbar-collapse .navbar-nav { padding: 0; }
          .navbar-collapse { border-radius: 0; }
          &.navbar-dark .navbar-collapse,
          &.navbar-light .navbar-collapse {
            background-color: transparent !important;
            box-shadow: none !important;
          }
          &.navbar-stuck .navbar-brand img {
            &.navbar-floating-logo { display: none; }
            &.navbar-stuck-logo { display: block; }
          }
        }

        $icon-size: 54px;

        .navbar-nav {
          align-items: flex-end;
          margin: 1rem 5rem 0 1rem;

          .nav-item { 
            margin: 0 1rem;
            padding: .5rem;            
            padding-bottom: 0;
            height: $icon-size;
            position: relative;

            &.active,
            &:hover {
              position: relative;
              &::before {
                content: '';
                background-color: $white;
                clip-path: url(#navTopClip);
                position: absolute;
                width: $icon-size * 2.75;
                height: $icon-size;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
              }              

              .nav-link {
                .icon {
                  background-color: $primary;
                  box-shadow: $box-shadow;
                  transform: translateY(0);    
                }
              }
            }
           }

          .nav-link {
            width: $icon-size;
            height: $icon-size;
            padding: 0;
            position: relative;

            .icon {
              position: absolute;
              z-index: 10;
              top: 0;
              border-radius: 50%;              
              width: 100%;
              height: 100%;
              transform: translateY(-$icon-size / 4);
              transition: {
                property: transform;
                duration: 0.3s;
                timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
              }
            }

            img {
              width: 100%;
              height: 100%;
              padding: .75rem;
              object-fit: contain;
            }
          }
        }

        .navbar-nav:not(.mega-nav) .nav-link.dropdown-toggle::after {
          display: none;
        }
        .mega-nav {
          &::after { display: block; }
          .dropdown-menu > .dropdown .dropdown-menu {
            top: .35rem;
          }
          .dropdown-menu > .mega-dropdown {
            .dropdown-menu {
              top: .375rem;
              min-height: 100%;
              min-height: calc(100% + .25rem);
              animation: none !important;
              &::before {
                position: absolute;
                top: 0;
                right: $navbar-mega-dropdown-column-width + $spacer;
                width: $border-width;
                height: 100%;
                background-color: $border-color;
                content: '';
              }
            }
          }
        }
        .dropdown-menu {
          margin-top: 0;
          @if $enable-shadows {
            border-color: $dropdown-bg;
          }
          background-color: $dropdown-bg;
          box-shadow: $dropdown-box-shadow;
          &.show { display: none; }
          > .dropdown {
            > .dropdown-toggle {
              position: relative;
              padding-right: $dropdown-item-padding-x * 1.5;
              @include caret(right);
              &::after {
                position: absolute;
                display: block;
                top: 50%;
                right: 1rem;
                margin-top: -.3em;
              }
            }
            .dropdown-menu {
              top: 0;
              right: auto;
              left: 100%;
              width: auto;
              margin: {
                top: -$dropdown-padding-y;
                right: 0;
                left: -.25rem;
              }
              background-color: $dropdown-bg;
            }
            &:hover > .dropdown-menu {
              animation: fade-in .25s ease-in-out;
            }
          }
        }
        .dropdown:hover > .dropdown-menu {
          display: block;
          animation: slide-up .25s ease-in-out;
        }
        .navbar-tool-text { display: block; }
      }
    }
  }
}

// Navbar themes

// White links against a dark background
.navbar-dark {
  .nav-link > i {
    //transition: $nav-link-transition;
    color: rgba($white, .5);
  }
  .nav-item:hover .nav-link:not(.disabled) {
    color: $navbar-dark-hover-color;
  }
  .nav-item.active .nav-link:not(.disabled) > i,
  .nav-item.show .nav-link:not(.disabled) > i,
  .nav-item.dropdown .nav-link:focus:not(.disabled) > i {
    color: $navbar-dark-active-color;
  }
  .navbar-tool-icon-box {
    &.bg-secondary { background-color: rgba($white, .08) !important; }
  }
  .navbar-tool-text {
    color: $navbar-dark-tool-color;
    > small {
      color: $navbar-dark-tool-text-small-color;
    }
  }
  .navbar-tool:hover .navbar-tool-text {
    color: $navbar-dark-tool-hover-color;
  }
  .mega-nav::after {
    //background-color: $border-light-color;
  }

  @include media-breakpoint-down(md) {
    .dropdown-menu,
    .dropdown-menu .dropdown-menu {
      border-color: transparent;
      background-color: rgba($black, .1);
      .dropdown-item {
        color: $navbar-dark-color;
        &:hover { color: $navbar-dark-hover-color; }
        &.disabled {  color: $navbar-dark-disabled-color; }
        &.border-bottom {
          //border-color: $border-light-color !important;
        }
      }
      .active .dropdown-item {
        color: $navbar-dark-active-color;
      }
    }
    .dropdown-menu .dropdown-menu {
      background-color: rgba($black, .08);
    }
    //.dropdown-divider { border-color: $border-light-color; }
    .text-heading { color: $white !important; }
    .text-muted { color: rgba($white, .5) !important; }
    .widget-title,
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 { color: $white !important; }
    .widget-list-link {
      //color: $widget-links-light-color;
      &:hover {
        //color: $widget-links-light-hover-color;
      }
    }
    .active > .widget-list-link {
      //color: $widget-links-light-active-color;
    }
  }
}

.navbar-nav.gray {

    .nav-item {
      &::before {
          content: '';
          background-color: $light !important;
        }     
    }
}