//
// Main content area
//

.login {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 8rem);

  @include media-breakpoint-up(lg) {
      min-height: calc(100vh - 4.5rem);
    }

  &-title {
    margin-top: -3rem;    
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    height: 20vh;

    @include media-breakpoint-up(sm) {
      height: 30vh;
    }

    &-graphic {
      height: calc(76px * 2.5797);
      width: 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 392.966 356.242'%3E%3Cdefs/%3E%3Cpath fill='%231a0e68' d='M186.72 0h206.246l-173.14 213.539c-15.125 18.649-23.581 42.168-22.168 66.142 1.562 26.562 14.062 57.811 57.811 76.561h-200S-23.3 324.73 6.893 242.457a133.039 133.039 0 0119.931-35.181z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      left: -50px;

      @include media-breakpoint-up(sm) {
        height: calc(101px * 2.5797);
      }
    }

    &-inner {
      background-color: $white;
      border-radius: 3.5625rem 0 0 3.5625rem;
      color: $primary;
      padding: .5rem 3rem;
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: .65rem;
      position: absolute;
      width: calc(100% - 75px);
      bottom: 0;
      right: 0;      

      @include media-breakpoint-up(sm) {
        font-size: 3.5625rem;
        width: calc(100% - 115px);
        margin-bottom: .75rem;
      }
    }
  }

  button[type=submit] {
    border-radius: 2rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}