//
// Main content area
//

.wx-details {
  &-img {
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      max-width: 500px;
    }

    &-element {
      &-standards {
        max-height: 10rem;
      }
      
      &-hazards {
        max-height: 10.5rem;
      }

      &-product-line {
        min-height: 13rem;
        width: 100%;
      }
    }
  }

  &-button {
    max-width: 320px;
    width: 100%;
  }
}

.applicable-img {
  width: 100px;
  height: 100px;
}