//
// Westex uniform styles
//

.uniform {
  &-modal{

    text-align: center;

    &-circle{
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      background-color: #08003D;
      margin-top: -25px;
    }

    &-icon{
      color:white;
    }

    &-title{
      font-size: 20px;
      color: #5B89BF;
    }

    &-content{
      @include media-breakpoint-down(sm) {
        max-width: 75%;
        left: 9%;
      }
    }

    &-swatch-container{
      margin-top: 1rem;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      height:50px;
    }
  }

  &-percentage {
    font-size: 1.875rem;
    color: $black;
    text-align: center;
    flex-grow: 1;
  }

  &-tools {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: .25rem 1rem;
    display: flex;
  }

  &-sidebar {
    position: absolute;
    right: 0;
    z-index: 100;
  }

  &-bookmark {
      background: transparent;
      border: 0;

    &:focus,
    &.focus {
      box-shadow: none;
      outline: none;
    }

    &.active,
    &:hover {
        .uniform-bookmark-icon {
          background-image: escape-svg($bookmark-solid-icon);
        }
    }

    &-icon {
      position: relative;
      width: 40px;
      height: 40px;
      display: block;
      background-image: escape-svg($bookmark-icon);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      @include transition($bg-img-transition);
    }
  }

  &-icon {
    background: transparent;
    border: 0;

    &:focus {
      outline: none;
    }

    &-male {
      position: relative;
      display: block;
      width: 54px;
      height: 54px;
      background-image: $mannequin-icon-male;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: white;

      &.active{
        background-image: $mannequin-icon-male-selected;
      }
    }

    &-female {
      position: relative;
      display: block;
      width: 54px;
      height: 54px;
      background-image: $mannequin-icon-female;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: white;

      &.active{
        background-image: $mannequin-icon-female-selected;
      }
    }
  }

  &-info {
    &-icon{
      position: relative;
      width: 40px;
      height: 40px;
      display: block;
      border: none;
      background-image: escape-svg($info-icon);
      background-color: transparent;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.mannequin {
  position: relative;
  height: subtract(100vh, 260px);
  display: flex;
  justify-content: center;

  &-section {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    min-height: subtract(100vh, 260px);
    max-height: subtract(100vh, 260px);

    -ms-flex-direction: row;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
  }

  &-toggle {
    z-index: 100;
    cursor: pointer;

    &-m {
      &-chest {
        width: calc((100vh - 250px) * 0.35);
        height: 42% !important;
        top: 16% !important;
        border-radius: 45% 30% 35% 30%;
      }

      &-head {
        margin-left: calc((100vh - 250px) * 0.03);
        width: calc((100vh - 250px) * 0.16);
        height: 19% !important;
        border-radius: 60% 60% 60% 70%;
      }

      &-legs {
        margin-left: calc((100vh - 250px) * 0.01);
        width: calc((100vh - 250px) * 0.24);
        height: 53% !important;
        top: 41% !important;
        border-radius: 30% 20% 25% 15%;
      }
    }

    &-f {
      &-chest {
        width: calc((100vh - 250px) * 0.28);
        height: 40% !important;
        top: 17% !important;
        border-radius: 40% 30% 30% 20%;
      }

      &-head {
        margin-left: calc((100vh - 250px) * 0.01);
        width: calc((100vh - 250px) * 0.15);
        height: 19% !important;
        top: 1% !important;
        border-radius: 90% 100% 100% 100%;
      }

      &-legs {
        margin-left: calc((100vh - 250px) * 0.01);
        width: calc((100vh - 250px) * 0.23);
        height: 52% !important;
        top: 43% !important;
        border-radius: 25% 10% 35% 15%;
      }
    }
  }

  img {
    object-fit: contain;
    height: 100%;
    width: auto;
  }

  .garment {
    height: 100%;
    position: absolute;
    top: 0;
    left: auto;

    img {
      opacity: 1;
      transition: {
        timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        duration: .75s;
        property: opacity;
      }
    }

    %btn-percent {
      @extend .btn;
      @extend .btn-accent;
      @extend .shadow;
      position: absolute;
      border-radius: 50%;
      font-size: 1.15rem;
      width: 60px;
      height: 60px;
      z-index: 100;
      padding: 0;
      opacity: 0 !important;
      transition: {
        timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        duration: .75s;
        delay: .25s;
        property: opacity;
      }

      &::after {
        content: '';
        background-color: $accent;
        height: 2px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform-origin: left;
      }
    }

    %highlight {
      display: block;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    @each $name, $y, $x, $deg, $w, $filename in $male-garment-types {
      &.m-#{$name} {
        .btn-percent {
          @extend %btn-percent;
          top: $y;
          left: $x;

          &::after {
            transform: rotate($deg);
            width: $w;
          }
        }

        &.active {
          z-index: 100;

          .btn-percent {
            opacity: 1 !important;
          }

          .highlight {
            @extend %highlight;
            background-image: $filename;
          }

          img {
            opacity: 0;
          }
        }
      }
    }

    @each $name, $y, $x, $deg, $w, $filename in $female-garment-types {
      &.f-#{$name} {
        .btn-percent {
          @extend %btn-percent;
          top: $y;
          left: $x;

          &::after {
            transform: rotate($deg);
            width: $w;
          }
        }

        &.active {
          z-index: 100;

          .btn-percent {
            opacity: 1 !important;
          }

          .highlight {
            @extend %highlight;
            background-image: $filename;
          }

          img {
            opacity: 0;
          }
        }
      }
    }
  }
}

.arrow {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin: 0.5rem;
  background-image: url("../assets/images/arrow.svg");
  background-repeat: no-repeat;
  background-size: 19px 30px;
  background-position: center;
  outline: none !important;

    &:focus {
      box-shadow: 0 0 0 2pt rgba(227, 29, 26, 0.5);
    }

    &-left {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
}
